<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
      [label]="'Assignee'"
      [categoryList]="categoryList"
      [placeholder]="'Search Entities'"
      [control]="formGroup?.controls?.assignee"
      [multi]="false"
      [selectedEntities]="[data?.assignee]"
      [viewMode]="fieldViewMode"
      [dropDown]="true"
      >
    </app-entity-category-tree-selector> -->
      <app-user-and-group-selector
        [label]="moduleFieldString + '.assignee.label' | translate: 'Assignee'"
        [placeholder]="moduleFieldString + '.assignee.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.assignee"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.criticality.label' | translate: 'Criticality'"
        [placeholder]="moduleFieldString + '.criticality.placeholder' | translate: 'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="listOfCriticality"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.criticality"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
    [label]="'Owner'"
    [categoryList]="categoryList"
    [placeholder]="'Search Entities'"
    [control]="formGroup?.controls?.issueOwner"
    [multi]="false"
    [selectedEntities]="[data?.issueOwner]"
    [viewMode]="fieldViewMode"
    [dropDown]="true"
    >
  </app-entity-category-tree-selector> -->
      <app-user-and-group-selector
        [label]="moduleFieldString + '.issueOwner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.issueOwner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.issueOwner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.sourceType.label' | translate: 'Relation Type'"
        [placeholder]="moduleFieldString + '.sourceType.placeholder' | translate: 'Select Item'"
        [name]="'multi-dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="targetTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.sourceType"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.sourceCode.label' | translate: 'Source'"
        [placeholder]="moduleFieldString + '.sourceCode.placeholder' | translate: 'Search Source'"
        [control]="formGroup?.controls?.sourceCode"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'label'"
        [optionValue]="'code'"
        [targetTypes]="[formGroup?.controls?.sourceType?.value]"
      >
      </app-target-code-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.issueTypeCode.label' | translate: 'Type'"
        [placeholder]="moduleFieldString + '.issueTypeCode.placeholder' | translate: 'Search Issue Types'"
        [control]="formGroup?.controls?.issueTypeCode"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ISSUE_TYPE']"
      >
      </app-target-code-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.parent.label' | translate: 'Parent'"
        [placeholder]="moduleFieldString + '.parent.placeholder' | translate: 'Search Issues'"
        [control]="formGroup?.controls?.parent"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [excludeCodes]="data?.code ? [data?.code] : []"
        [targetTypes]="['ISSUE']"
      >
      </app-target-code-selector>
      <!-- <app-target-code-selector
  [excludeCodes]="data?.code ? [data?.code] : []"
  [label]="'Parent'"
  [placeholder]="'Search Issues'"
  [control]="formGroup?.controls?.parent"
  [viewMode]="fieldViewMode"
  [multi]="false"
  [optionLabel]="'name'"
  [optionValue]="'code'"
  [targetTypes]="['ISSUE']"
  >
</app-target-code-selector> -->
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>

    </div>
  </div>
  <!-- Used to have special condition on cancel (showCancelButton) -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' || showCancelButton) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
